import {useEffect} from 'react'
import {KTCard, KTSVG} from '../../../../../../../_metronic/helpers'
import {useSchoolFormStore, useSchoolState} from '../states/school.state'
import ReactPaginate from 'react-paginate'

import {LoadingComponent} from '../../../../crm/properties-management/components/loading.component'
import {useNavigate} from 'react-router-dom'
import {FormAction} from '../../../../../../interfaces/form-action-interfaces'
import SchoolModalComponent from './school-modal.component'

const SchoolListComponent = () => {
  const navigate = useNavigate()
  const {onDelete, schoolItems, getAllSchool, schoolLoading, schoolModel} = useSchoolState()
  const {setField, field, generateForms, submitDone, formLoading, reset} = useSchoolFormStore()

  useEffect(() => {
    getAllSchool({page: 0, size: 10})
  }, [])

  useEffect(() => {
    getAllSchool({page: 0, size: 10})
    if (submitDone === true && formLoading === false) {
      reset()
    }
  }, [submitDone, formLoading])

  const handlePageClick = (e: any) => {
    const selectedPage = e.selected
    getAllSchool({page: selectedPage, size: 10})
  }

  return (
    <>
      <div className='py-5'>
        <div className='rounded border p-5' style={{backgroundColor: 'white'}}>
          <form
            data-kt-search-element='form'
            className='w-100 position-relative'
            autoComplete='off'
          >
            {/*begin::Hidden input(Added to disable form autocomplete)*/}
            <input type='hidden' />
            {/*end::Hidden input*/}
            {/*begin::Icon*/}
            {/*begin::Svg Icon | path: icons/duotune/general/gen021.svg*/}
            <span className='svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y'>
              <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-2' />
            </span>
            {/*end::Svg Icon*/} {/*end::Icon*/}
            {/*begin::Input*/}
            {/* <input
              type='text'
              className='form-control form-control-lg form-control-solid px-15'
              name='search'
              value={title}
              //   onChange={(e) => setTitle(e?.target?.value)}
              placeholder='Cari berdasarkan nama branch'
              data-kt-search-element='input'
            /> */}
            {/*end::Input*/}
          </form>
        </div>
      </div>
      <KTCard>
        <div className='card-header border-0 pt-6'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>School</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>School Management</span>
          </h3>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-primary'
              onClick={(e) => {
                e.preventDefault()
                setField(null)
                generateForms({
                  title: 'Create New School',
                  subtitle: 'Create New School',
                  action: FormAction.CREATE,
                })
              }}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
              Create New School
            </button>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {schoolLoading ? <LoadingComponent /> : <></>}
            <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <th>School Name</th>
                  <th>Level</th>
                  <th>Code</th>
                  <th>Province</th>
                  <td>Detail</td>
                  <th className='text-end'>Actions</th>
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                {schoolItems?.length === 0 ? (
                  <tr>
                    <td colSpan={6}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No Properties Found
                      </div>
                    </td>
                  </tr>
                ) : (
                  schoolItems?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.name}</td>
                        <td>{item?.level}</td>
                        <td>{item.school_code}</td>
                        <td>{item.province_name}</td>
                        <td>
                          <span
                            className='badge badge-success'
                            style={{
                              cursor: 'pointer',
                            }}
                            onClick={(e) => {
                              e.preventDefault()
                              navigate(
                                `/apps/program/taqur/student-management/students/${item?.id}`
                              )
                            }}
                          >
                            Detail
                          </span>
                        </td>
                        <td className='text-end'>
                          <a
                            href={`#`}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            onClick={(e) => {
                              e.preventDefault()
                              setField(item)
                              generateForms({
                                title: 'Update School',
                                subtitle: 'Update School',
                                action: FormAction.UPDATE,
                                data: item,
                                id: item.id,
                              })
                            }}
                          >
                            <KTSVG
                              path='/media/icons/duotune/art/art005.svg'
                              className='svg-icon-3'
                            />
                          </a>
                          <a
                            href='#'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            onClick={(e) => {
                              e.preventDefault()
                              onDelete(item.id, {
                                page: 0,
                                size: 10,
                              })
                            }}
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/gen027.svg'
                              className='svg-icon-3'
                            />
                          </a>
                        </td>
                      </tr>
                    )
                  })
                )}
              </tbody>
            </table>
          </div>
          <div className='card-footer'>
            <ReactPaginate
              previousLabel={'«'}
              nextLabel={'»'}
              breakLabel={'...'}
              breakClassName={'page-item'}
              breakLinkClassName={'page-link'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              pageCount={schoolModel?.data?.total_pages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={10}
              onPageChange={handlePageClick}
              containerClassName={'pagination pagination-sm m-0 float-right'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              activeClassName={'active'}
            />
          </div>
        </div>
      </KTCard>
      {field !== undefined && <SchoolModalComponent />}
    </>
  )
}

const SchoolListWrapper = () => {
  return (
    <>
      <SchoolListComponent />
    </>
  )
}

export default SchoolListWrapper
