import {AxiosError} from 'axios'
import {ApiProgram} from '../../../../../api/api'
import {GeneralInterface} from '../../../../../interfaces/general.interface'
import {DonationTypeSingleResultInterface} from '../interfaces/donation-type-single-result.interface'
import {
  DonationTypeResultInterface,
  DonationTypeData,
} from '../interfaces/donation-type-result.interface'

export interface GetAllDonationTypeParam {
  page?: number
  size?: number
  pipeline_id?: string
  stage_id?: string
  query?: string
}

export const GetAllDonationType = async (
  params: GetAllDonationTypeParam
): Promise<DonationTypeResultInterface> => {
  try {
    let response = await ApiProgram.get('/donation-types', {
      params: params,
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: DonationTypeResultInterface = a.response?.data
    return response
  }
}

export const ShowDonationType = async (id: string): Promise<DonationTypeSingleResultInterface> => {
  try {
    let response = await ApiProgram.get('/donation-types/' + id)
    return response.data as DonationTypeSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: DonationTypeSingleResultInterface = a.response?.data
    return response
  }
}

export const SubmitDonationType = async (
  form: DonationTypeData
): Promise<DonationTypeSingleResultInterface> => {
  try {
    let response = await ApiProgram.post('/donation-types', form)
    return response.data as DonationTypeSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: DonationTypeSingleResultInterface = a.response?.data
    return response
  }
}

export const UpdateDonationType = async (
  id: string,
  form: DonationTypeData
): Promise<DonationTypeSingleResultInterface> => {
  try {
    let response = await ApiProgram.put('/donation-types/' + id, form)
    return response.data as DonationTypeSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: DonationTypeSingleResultInterface = a.response?.data
    return response
  }
}

export const DeleteDonationType = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiProgram.delete('/donation-types/' + id)
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}
