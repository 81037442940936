import {useEffect, useMemo} from 'react'
import ReactFlow, {Controls, Background} from 'reactflow'

// 👇 you need to import the reactflow styles
import 'reactflow/dist/style.css'
import {KTCard, KTSVG} from '../../../../../../../_metronic/helpers'
import {
  useFlowBuilderFormState,
  useFlowBuilderStore,
  useReactFlowStore,
} from '../../states/flow-builder.state'
import {useWhatsappChannelStore} from '../../../whatsapp-channel-management/states/whatsapp-channel.state'
import {useAuth} from '../../../../../auth'
import {useLocation, useNavigate} from 'react-router-dom'
import {FormProps} from '../../../../../../interfaces/form-action-interfaces'
import FormsComponent from '../../../../../forms/components/forms.component'
import FlowBuilderFormDetailModalComponent from '../../components/flow-builder-form-detail-modal.component'
import CardNodeComponentV2 from '../../components/card-node-v2.component'

// const initialEdges: Edge[] = [{ id: 'e1-2', source: '1', target: '2', animated: true  }];

const FlowBuilderTransactional = () => {
  const nodeTypes = useMemo(() => ({cardNodeComponent: CardNodeComponentV2}), [])
  const {
    nodes,
    edges,
    onEdgesChange,
    onNodesChange,
    onConnect,
    addNodes,
    generateNodes,
    generateEdges,
    resetReactFlow,
  } = useReactFlowStore()
  const {
    reset,
    field,
    setFormSelectData,
    generateForms,
    forms,
    setForms,
    onSubmit,
    submitDone,
    formLoading,
  } = useFlowBuilderFormState()
  const {currentUser} = useAuth()
  const {selectedNode} = useFlowBuilderStore()
  const location = useLocation()
  const navigate = useNavigate()
  const {getWhatsapps, whatsappSelectDatas} = useWhatsappChannelStore()
  const {getTransactionFlows} = useFlowBuilderStore()

  useEffect(() => {
    if (location !== undefined) {
      const formProps = location.state as FormProps
      generateForms(formProps)
    }
  }, [location])

  useEffect(() => {
    generateNodes(field)
    generateEdges(field)
  }, [field])

  useEffect(() => {
    if (currentUser !== undefined) {
      getWhatsapps({
        page: 0,
        size: 10,
        company_channel_id: currentUser?.data?.company_channel?.id,
      })
    }
  }, [currentUser])

  useEffect(() => {
    return () => {
      resetReactFlow()
    }
  }, [])

  useEffect(() => {
    if (whatsappSelectDatas?.length > 0 && forms?.length > 0) {
      setFormSelectData(2, whatsappSelectDatas)
    }
  }, [whatsappSelectDatas])

  useEffect(() => {
    if (submitDone === true && formLoading === false) {
      getTransactionFlows({
        page: 0,
        size: 10,
        company_id: currentUser?.data?.company?.id,
      })
      reset()
      navigate(-1)
    }
  }, [submitDone, formLoading])

  return (
    <>
      <KTCard>
        <div className='card-header border-0 pt-6'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Flow Builder</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Flow Builder</span>
          </h3>
        </div>
        <div className='card-body py-3'>
          <div className='w-100'>
            <div className='mb-10 fv-row fv-plugins-icon-container'>
              <FormsComponent
                forms={forms}
                setForms={setForms}
                uploadFileCb={(e) => console.log(e)}
              />
            </div>
            <label className={`d-block fw-semibold fs-6 mb-5`}>Flow</label>
            <div
              className='d-flex flex-row overflow-auto'
              style={{
                marginBottom: 10,
              }}
            >
              <button
                type='button'
                className='btn btn-primary'
                onClick={(e) => {
                  addNodes()
                }}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                Create New Flow
              </button>
            </div>
            <div className='d-flex overflow-auto h-550px'>
              <div
                style={{
                  flexGrow: 1,
                  fontSize: 12,
                }}
              >
                <ReactFlow
                  nodes={nodes}
                  edges={edges}
                  nodeTypes={nodeTypes}
                  onEdgesChange={onEdgesChange}
                  onNodesChange={onNodesChange}
                  onConnect={onConnect}
                  proOptions={{
                    hideAttribution: true,
                    account: process.env.REACT_APP_REACTFLOW_ACCOUNT,
                  }}
                >
                  {/* <MiniMap /> */}
                  <Controls />
                  <Background
                    color='#000'
                    style={{
                      border: '1px solid #E4E6EF',
                      borderRadius: '5px',
                    }}
                  />
                </ReactFlow>
              </div>
            </div>
          </div>
        </div>
        <div className='card-footer d-flex justify-content-end'>
          <button
            type='button'
            className='btn btn-primary'
            onClick={(e) => {
              e.preventDefault()
              onSubmit(nodes, edges, currentUser?.data?.company?.id)
            }}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            Submit
          </button>
        </div>
      </KTCard>
      {selectedNode !== undefined && <FlowBuilderFormDetailModalComponent />}
    </>
  )
}

export default FlowBuilderTransactional
