import {create} from 'zustand'
import {ShowUser} from '../../auth/user-management/providers/user.provider'
import {CustomerProfileData} from '../../crm/customer-management/interfaces/customer-profile-result.interface'
import {CustomerSegmentData} from '../../crm/customer-management/interfaces/customer-segment-result.interface'
import {
  GetCustomerProfile,
  GetCustomerSegment,
  GetTotalCustomer,
} from '../../crm/customer-management/providers/customer.provider'
import {RoomKpiData} from '../../crm/whatsapp-channel-management/interfaces/room-result.interface'
import {
  GetAdminHandleRoom,
  GetTotalRoom,
} from '../../crm/whatsapp-channel-management/providers/room.provider'
import {DonationByPeriodeData} from '../../program/donation/interfaces/donation-by-periode-result.interface'
import {DonaitonChannelData} from '../../program/donation/interfaces/donation-channel-result.interface'
import {
  DonationDashboardParam,
  GetDonationByPeriode,
  GetDonationChannelPeriode,
  GetDonationTransactionByPeriode,
  GetTotalDonation,
} from '../../program/donation/providers/donation.provider'
import {TopProgramData} from '../../program/program/interfaces/top-program-result.interface'
import {
  GetTopProgram,
  GetTotalProgram,
  ProgramDashboardParam,
} from '../../program/program/providers/program.provider'

interface DashboardState {
  // filter date
  startDate: Date
  endDate: Date
  setStartDate: (date: Date) => void
  setEndDate: (date: Date) => void

  // total customer
  totalCustomerLoading: boolean
  totalCustomer: number
  getTotalCustomer: (params: DonationDashboardParam) => void

  // total donation
  totalDonationLoading: boolean
  totalDonation: number
  getTotalDonation: (params: DonationDashboardParam) => void

  // total active program
  totalActiveProgramLoading: boolean
  totalActiveProgram: number
  getTotalActiveProgram: () => void

  // total new case
  totalRoomsLoading: boolean
  totalNewCase: number
  totalOpenCase: number
  totalCloseCase: number
  getTotalRoom: () => void

  // top 10 program
  topProgramLoading: boolean
  topPrograms: TopProgramData[]
  getTopPrograms: (params: ProgramDashboardParam) => void

  // donation by periode
  donationByPeriodeLoading: boolean
  donationByPeriodes: DonationByPeriodeData[]
  getDonationByPeriode: (periode: string) => void

  // donation transaction by periode
  donationTrxByPeriodeLoading: boolean
  donationTrxByPeriodes: DonationByPeriodeData[]
  getDonationTrxByPeriode: (periode: string) => void

  // customer segment
  customerSegmentLoading: boolean
  customerSegments: CustomerSegmentData[]
  getCustomerSegment: () => void

  // customer profile
  customerProfileLoading: boolean
  customerProfiles: CustomerProfileData[]
  getCustomerProfile: () => void

  // donation channel
  donationChannelLoading: boolean
  donationChannels: DonaitonChannelData[]
  getDonationChannel: (periode: string) => void

  // rooms kpi
  roomsHandledLoading?: boolean
  roomsHandled?: RoomKpiData[]
  getRoomsHandled?: () => void
}

export const useDashboardStore = create<DashboardState>((set, get) => ({
  startDate: undefined,
  endDate: undefined,
  setStartDate: (date: Date) => {
    set({startDate: date})
  },
  setEndDate: (date: Date) => {
    set({endDate: date})
  },
  totalCustomerLoading: false,
  totalCustomer: 0,
  getTotalCustomer: async (params: DonationDashboardParam) => {
    set({totalCustomerLoading: true})
    const response = await GetTotalCustomer(params)
    if (response.status) {
      set({totalCustomer: response.data as number})
    } else {
      set({totalCustomer: 0})
    }
    setTimeout(() => {
      set({totalCustomerLoading: false})
    }, 2000)
  },
  totalDonationLoading: false,
  totalDonation: 0,
  getTotalDonation: async (params: DonationDashboardParam) => {
    set({totalDonationLoading: true})
    const response = await GetTotalDonation(params)
    if (response.status) {
      set({totalDonation: response.data as number})
    } else {
      set({totalDonation: 0})
    }
    setTimeout(() => {
      set({totalDonationLoading: false})
    }, 2000)
  },
  totalActiveProgramLoading: false,
  totalActiveProgram: 0,
  getTotalActiveProgram: async () => {
    set({totalActiveProgramLoading: true})
    const response = await GetTotalProgram()
    if (response.status) {
      set({totalActiveProgram: response.data as number})
    } else {
      set({totalActiveProgram: 0})
    }
    setTimeout(() => {
      set({totalActiveProgramLoading: false})
    }, 2000)
  },
  totalRoomsLoading: false,
  totalNewCase: 0,
  totalOpenCase: 0,
  totalCloseCase: 0,
  getTotalRoom: async () => {
    set({totalRoomsLoading: true})
    const response = await GetTotalRoom()
    if (response.status) {
      set({
        totalNewCase: response.data.total_new_case,
        totalOpenCase: response.data.total_open_case,
        totalCloseCase: response.data.total_close_case,
      })
    }
    set({totalRoomsLoading: false})
  },
  topProgramLoading: false,
  topPrograms: [],
  getTopPrograms: async (params: ProgramDashboardParam) => {
    set({topProgramLoading: true})
    const response = await GetTopProgram(params)
    if (response.status) {
      if (response?.data?.length > 0) {
        set({topPrograms: response.data})
      } else {
        set({topPrograms: []})
      }
    } else {
      set({topPrograms: []})
    }
    set({topProgramLoading: false})
  },
  donationByPeriodeLoading: false,
  donationByPeriodes: [],
  getDonationByPeriode: async (periode: string) => {
    set({donationByPeriodeLoading: true})
    const response = await GetDonationByPeriode(periode)
    if (response.status) {
      if (response.data?.length > 0) {
        set({donationByPeriodes: response.data})
      } else {
        set({donationByPeriodes: []})
      }
    } else {
      set({donationByPeriodes: []})
    }
    set({donationByPeriodeLoading: false})
  },
  donationTrxByPeriodeLoading: false,
  donationTrxByPeriodes: [],
  getDonationTrxByPeriode: async (periode: string) => {
    set({donationTrxByPeriodeLoading: true})
    const response = await GetDonationTransactionByPeriode(periode)
    if (response.status) {
      if (response.data?.length > 0) {
        set({donationTrxByPeriodes: response.data})
      } else {
        set({donationTrxByPeriodes: []})
      }
    } else {
      set({donationTrxByPeriodes: []})
    }
    set({donationTrxByPeriodeLoading: false})
  },

  // customer segment
  customerSegmentLoading: false,
  customerSegments: [],
  getCustomerSegment: async () => {
    set({customerSegmentLoading: true})
    const response = await GetCustomerSegment()
    if (response.status) {
      if (response.data?.length > 0) {
        set({customerSegments: response.data})
      } else {
        set({customerSegments: []})
      }
    } else {
      set({customerSegments: []})
    }
    set({customerSegmentLoading: false})
  },

  // customer profile
  customerProfileLoading: false,
  customerProfiles: [],
  getCustomerProfile: async () => {
    set({customerProfileLoading: true})
    const response = await GetCustomerProfile()
    if (response.status) {
      if (response.data?.length > 0) {
        set({customerProfiles: response.data})
      } else {
        set({customerProfiles: []})
      }
    } else {
      set({customerSegments: []})
    }
    set({customerProfileLoading: false})
  },

  // donation channel
  donationChannelLoading: false,
  donationChannels: [],
  getDonationChannel: async (periode: string) => {
    set({donationChannelLoading: true, donationChannels: []})
    const response = await GetDonationChannelPeriode(periode)
    if (response.status) {
      if (response.data?.length > 0) {
        set({donationChannels: response.data})
      } else {
        set({donationChannels: []})
      }
    } else {
      set({donationChannels: []})
    }
    set({donationChannelLoading: false})
  },

  // rooms kpi
  roomsHandledLoading: false,
  roomsHandled: [],
  getRoomsHandled: async () => {
    set({roomsHandledLoading: true})
    const response = await GetAdminHandleRoom()
    const roomsHandled: RoomKpiData[] = []
    if (response.data !== null) {
      for (const item of response.data) {
        const admin = await ShowUser(item.handle_by)
        if (admin.status) {
          roomsHandled.push({
            handle_by: admin?.data?.full_name,
            totals: item.totals,
          })
        }
      }
      set({roomsHandled: roomsHandled})
    } else {
      set({roomsHandled: []})
    }
    set({roomsHandledLoading: false})
  },
}))
