import {useContext, useEffect, useRef, useState} from 'react'
import {toast} from 'react-toastify'
import {useMessageTemplateStore} from '../../../app/modules/apps/crm/message-template-management/states/message-template.state'
import {RoomData} from '../../../app/modules/apps/crm/whatsapp-channel-management/interfaces/room-result.interface'
import {useWhatsappChannelStore} from '../../../app/modules/apps/crm/whatsapp-channel-management/states/whatsapp-channel.state'
import {useAuth} from '../../../app/modules/auth'
import {SelectWithText} from '../../../app/modules/forms/components/input.component'
import {UploadContext, UploadContextType} from '../../../app/modules/uploads/states/upload-context'
import {KTSVG, MessageModel} from '../../helpers'
import {allowedMessageAttachment} from '../../helpers/allowed-message-attachment'
import {generateImageUrl} from '../../helpers/generate-image-url'

interface MessageBoxComponentProps {
  isDrawer?: boolean
  activeRoom?: RoomData
  setMessages?: React.Dispatch<React.SetStateAction<MessageModel[]>>
  messages?: MessageModel[]
  messagesEndRef: React.RefObject<HTMLDivElement>
}

const MessageBoxComponent: React.FC<MessageBoxComponentProps> = ({
  isDrawer,
  activeRoom,
  setMessages,
  messages,
  messagesEndRef,
}) => {
  const inputFile = useRef<HTMLInputElement>()

  const [chatUpdateFlag, toggleChatUpdateFlat] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const {
    sendWhatsappMessage,
    setButtonOrListsMessage,
    messageButtons,
    messageLists,
    footer,
    title,
  } = useWhatsappChannelStore()
  const {setSingleFile, resultSingleFile, setResultSingleFile, uploading} =
    useContext<UploadContextType>(UploadContext)
  const {messageTemplateSelectDatas, getMessageTemplates} = useMessageTemplateStore()
  const {currentUser} = useAuth()

  useEffect(() => {
    getMessageTemplates({page: 0, size: 100})
  }, [])

  const sendMessage = () => {
    if (message === '') {
      toast.error('Please fill message')
      return
    }

    if (uploading === true) {
      toast.error('Wait, file still uploading')
      return
    }

    sendWhatsappMessage(
      activeRoom?.company_channel_property?.account_id,
      activeRoom?.sender?.number,
      message,
      resultSingleFile
    )
    setMessage('')
    toggleChatUpdateFlat(!chatUpdateFlag)

    messagesEndRef.current?.scrollIntoView({behavior: 'smooth'})

    const newMessage: MessageModel = {
      user: activeRoom?.receiver?.remoteJid?.split(':')[0],
      type: 'out',
      text: message,
      time: 'Just now',
      pushName: 'You',
      file: resultSingleFile?.data?.id ?? '',
      mimeType: resultSingleFile?.data?.mimetype ?? '',
      buttons: messageButtons,
      lists: messageLists,
      footer: footer,
      title: title,
    }
    const test = [...messages]
    test.push(newMessage)

    setTimeout(() => {
      setMessages(test)
      toggleChatUpdateFlat((flag) => !flag)
      setResultSingleFile(undefined)
    }, 500)
  }

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      sendMessage()
    }
  }

  return (
    <>
      <div
        className='card-footer pt-4'
        id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
      >
        <SelectWithText
          title='Message Template'
          placeholder='Message Template'
          selectData={messageTemplateSelectDatas}
          id={'message_template'}
          name={'message_template'}
          type={'select-with-text'}
          onChangeSelectWithText={(e) => {
            if (e) {
              const textTransform = e.label?.replaceAll(
                '{admin.name}',
                currentUser?.data?.full_name
              )
              setMessage(textTransform)
            }
          }}
        />

        <input
          type={'file'}
          hidden={true}
          id={'file'}
          name={'file'}
          ref={inputFile}
          multiple={false}
          onChange={(e) => {
            const file = e.target.files[0]
            setSingleFile(file)
          }}
          accept={allowedMessageAttachment}
        />

        {uploading === true ? <FileBoxComponent /> : <></>}

        {resultSingleFile !== undefined && uploading !== undefined ? <FileBoxComponent /> : <></>}

        <textarea
          className='form-control mb-3'
          rows={1}
          data-kt-element='input'
          placeholder='Type a message'
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={onEnterPress}
          required={true}
        ></textarea>

        <div className='d-flex flex-stack'>
          <div className='d-flex align-items-center me-2'>
            <button
              className='btn btn-sm btn-icon btn-active-light-primary me-1'
              type='button'
              onClick={(e) => {
                inputFile.current.click()
              }}
            >
              <i className='bi bi-paperclip fs-3'></i>
            </button>
            <span className='fw-semibold text-danger'>
              Only image, video, pdf, xls, xlsx, doc, docx, ppt, pptx can be upload
            </span>
          </div>
          <button
            className='btn btn-primary'
            type='button'
            data-kt-element='send'
            onClick={sendMessage}
          >
            Send
          </button>
        </div>
        <div className='d-flex'>
          <button
            className={`btn btn-sm btn-${messageButtons?.length > 0 ? 'success' : 'primary'} me-5`}
            type='button'
            data-kt-element='send'
            onClick={(e) => {
              setButtonOrListsMessage('button')
            }}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            {messageButtons?.length > 0 ? 'Button Added' : 'Add Button'}
          </button>
          <button
            className={`btn btn-sm btn-${messageLists?.length > 0 ? 'success' : 'primary'} me-5`}
            type='button'
            data-kt-element='send'
            onClick={(e) => {
              setButtonOrListsMessage('list')
            }}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            {messageLists?.length > 0 ? 'List Added' : 'Add List'}
          </button>
        </div>
      </div>
    </>
  )
}

const FileBoxComponent = () => {
  const {resultSingleFile, uploading, setResultSingleFile} =
    useContext<UploadContextType>(UploadContext)

  const generateThumbnail = () => {
    if (resultSingleFile?.data?.mimetype?.includes('image')) {
      return (
        <div
          className='image-input-wrapper w-125px h-125px'
          style={{
            backgroundImage: `url(${generateImageUrl(resultSingleFile?.data?.id)})`,
            objectFit: 'cover',
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {uploading === true ? (
            <span className={`spinner-border spinner-border-lg text-primary`}></span>
          ) : (
            <></>
          )}
        </div>
      )
    }

    if (resultSingleFile?.data?.mimetype?.includes('pdf')) {
      return (
        <div
          className='image-input-wrapper w-125px h-125px'
          style={{
            objectFit: 'cover',
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {uploading === true ? (
            <span className={`spinner-border spinner-border-lg text-primary`}></span>
          ) : (
            <div className='symbol symbol-45px symbol-circle'>
              <span className='symbol-label bg-light-danger text-danger fs-6 fw-bold'>PDF</span>
            </div>
          )}
        </div>
      )
    }

    if (
      resultSingleFile?.data?.mimetype?.includes('presentation') ||
      resultSingleFile?.data?.mimetype?.includes('powerpoint')
    ) {
      return (
        <div
          className='image-input-wrapper w-125px h-125px'
          style={{
            objectFit: 'cover',
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {uploading === true ? (
            <span className={`spinner-border spinner-border-lg text-primary`}></span>
          ) : (
            <div className='symbol symbol-45px symbol-circle'>
              <span className='symbol-label bg-light-danger text-danger fs-6 fw-bold'>PPT</span>
            </div>
          )}
        </div>
      )
    }

    if (
      resultSingleFile?.data?.mimetype?.includes('msword') ||
      resultSingleFile?.data?.mimetype?.includes('wordprocessingml')
    ) {
      return (
        <div
          className='image-input-wrapper w-125px h-125px'
          style={{
            objectFit: 'cover',
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {uploading === true ? (
            <span className={`spinner-border spinner-border-lg text-primary`}></span>
          ) : (
            <div className='symbol symbol-45px symbol-circle'>
              <span className='symbol-label bg-light-danger text-danger fs-6 fw-bold'>DOC</span>
            </div>
          )}
        </div>
      )
    }

    if (
      resultSingleFile?.data?.mimetype?.includes('excel') ||
      resultSingleFile?.data?.mimetype?.includes('spreadsheetml')
    ) {
      return (
        <div
          className='image-input-wrapper w-125px h-125px'
          style={{
            objectFit: 'cover',
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {uploading === true ? (
            <span className={`spinner-border spinner-border-lg text-primary`}></span>
          ) : (
            <div className='symbol symbol-45px symbol-circle'>
              <span className='symbol-label bg-light-danger text-danger fs-6 fw-bold'>XLS</span>
            </div>
          )}
        </div>
      )
    }

    if (resultSingleFile?.data?.mimetype?.includes('video')) {
      return (
        <div
          className='image-input-wrapper w-125px h-125px'
          style={{
            objectFit: 'cover',
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {uploading === true ? (
            <span className={`spinner-border spinner-border-lg text-primary`}></span>
          ) : (
            <div className='symbol symbol-45px symbol-circle'>
              <span className='symbol-label bg-light-danger text-danger fs-6 fw-bold'>VIDEO</span>
            </div>
          )}
        </div>
      )
    }

    return (
      <div
        className='image-input-wrapper w-125px h-125px'
        style={{
          objectFit: 'cover',
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {uploading === true ? (
          <span className={`spinner-border spinner-border-lg text-primary`}></span>
        ) : (
          <></>
        )}
      </div>
    )
  }

  return (
    <div
      className='image-input image-input-outline mb-5'
      data-kt-image-input='true'
      style={{
        backgroundImage: 'url("/metronic8/demo1/assets/media/svg/avatars/blank.svg")',
      }}
    >
      {/*begin::Preview existing avatar*/}
      {generateThumbnail()}
      {/*end::Label*/}

      {/*begin::Cancel*/}
      <span
        className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
        data-kt-image-input-action='cancel'
        data-bs-toggle='tooltip'
        aria-label='Cancel avatar'
        data-bs-original-title='Cancel avatar'
        data-kt-initialized={1}
      >
        <i className='bi bi-x fs-2' />
      </span>
      {/*end::Cancel*/}
      {/*begin::Remove*/}
      <span
        className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
        data-kt-image-input-action='remove'
        data-bs-toggle='tooltip'
        aria-label='Remove avatar'
        data-bs-original-title='Remove avatar'
        data-kt-initialized={1}
        onClick={(e) => {
          setResultSingleFile(undefined)
        }}
      >
        <i className='bi bi-x fs-2' />
      </span>
      {/*end::Remove*/}
    </div>
  )
}

export default MessageBoxComponent
