import React, {createContext, useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {MultipleFileUploadResult} from '../interfaces/multiple-file-upload-result'
import {SingleFileUploadResult} from '../interfaces/single-file-upload-result'
import {UploadMultiple, UploadSingle, UploadSingleVideo} from '../providers/upload-provider'

export type UploadContextType = {
  uploading: boolean
  singleFile: File
  singleFileVideo: File
  setSingleFile: React.Dispatch<React.SetStateAction<File>>
  setSingleFileVideo: React.Dispatch<React.SetStateAction<File>>
  resultSingleFile?: SingleFileUploadResult
  total: number
  loaded: number
  uploadFileManual: (file: File) => Promise<SingleFileUploadResult>
  multipleFile: File[]
  setMultipleFile: React.Dispatch<React.SetStateAction<File[]>>
  resultMultipleFile: MultipleFileUploadResult
  setResultSingleFile: React.Dispatch<React.SetStateAction<SingleFileUploadResult>>
}

export const UploadContext = createContext<UploadContextType | null>(null)

interface UploadContextProviderProps {
  children: React.ReactNode
}

export const UploadContextProvider: React.FC<UploadContextProviderProps> = ({children}) => {
  const [singleFile, setSingleFile] = useState<File>()
  const [singleFileVideo, setSingleFileVideo] = useState<File>()
  const [multipleFile, setMultipleFile] = useState<File[]>([])
  const [resultSingleFile, setResultSingleFile] = useState<SingleFileUploadResult>()
  const [resultMultipleFile, setResultMultipleFile] = useState<MultipleFileUploadResult>()
  const [uploading, setUploading] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [loaded, setLoaded] = useState<number>(0)

  const multipleUpload = async () => {
    if (multipleFile?.length > 0) {
      setUploading(true)
      const res = await UploadMultiple(multipleFile, (progress) => {
        console.log(progress)
        setTotal(progress.total)
        setLoaded(progress.loaded)
      })
      setResultMultipleFile(res)
      if (res.status) {
        toast.success(res.message)
      } else {
        toast.error(res.message)
      }
      setUploading(false)
    }
  }

  const singleUploadVideo = async () => {
    if (singleFileVideo !== undefined) {
      setUploading(true)
      let res = await UploadSingleVideo(singleFileVideo, (progress) => {
        console.log(progress)
        setTotal(progress.total)
        setLoaded(progress.loaded)
      })
      setResultSingleFile(res)
      if (res.status) {
        toast.success(res.message)
      } else {
        toast.error(res.message)
      }
      setUploading(false)
    }
  }

  const singleUpload = async () => {
    if (singleFile !== undefined) {
      setUploading(true)
      let res = await UploadSingle(singleFile, (progress) => {
        setTotal(progress.total)
        setLoaded(progress.loaded)
      })
      setResultSingleFile(res)
      if (res.status) {
        toast.success(res.message)
      } else {
        toast.error(res.message)
      }
      setUploading(false)
    }
  }

  useEffect(() => {
    if (singleFile !== null) {
      singleUpload()
    }
  }, [singleFile])

  useEffect(() => {
    singleUploadVideo()
  }, [singleFileVideo])

  useEffect(() => {
    multipleUpload()
  }, [multipleFile])

  const uploadFileManual = async (file: File): Promise<SingleFileUploadResult> => {
    return new Promise((resolve, reject) => {
      UploadSingle(file, (progress) => {})
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  }

  return (
    <UploadContext.Provider
      value={{
        singleFile,
        setSingleFile,
        uploading,
        resultSingleFile,
        loaded,
        total,
        uploadFileManual,
        multipleFile,
        setMultipleFile,
        resultMultipleFile,
        singleFileVideo,
        setSingleFileVideo,
        setResultSingleFile,
      }}
    >
      {children}
    </UploadContext.Provider>
  )
}
