import {useEffect} from 'react'
import ReactPaginate from 'react-paginate'
import {KTCard} from '../../../../../../_metronic/helpers'
import {LoadingComponent} from '../../../crm/properties-management/components/loading.component'
import {
  useProgramCategoryStore,
  useProgramCategoryFormStore,
} from '../../program-category/states/program-category.state'
import useCopyToClipboard from './copytToClipBoard'
import {useAuth} from '../../../../auth'
import {toast} from 'react-toastify'

const GeneratelinkListComponent = () => {
  const [value, copy] = useCopyToClipboard()
  const {currentUser} = useAuth()
  const {getProgramCategorys, programCategoryLoading, programCategoryItems, programCategoryModel} =
    useProgramCategoryStore()
  const {submitDone, formLoading, reset} = useProgramCategoryFormStore()

  useEffect(() => {
    console.log(value)
    getProgramCategorys({page: 0, size: 10})
  }, [])

  useEffect(() => {
    if (submitDone === true && formLoading === false) {
      getProgramCategorys({page: 0, size: 10})
      reset()
    }
  }, [submitDone, formLoading])

  const handlePageClick = (e: any) => {
    const selectedPage = e.selected
    getProgramCategorys({page: selectedPage, size: 10})
  }

  return (
    <>
      <KTCard>
        <div className='card-header border-0 pt-6'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              Generate Link Affiliate By Program Category
            </span>
          </h3>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {programCategoryLoading ? <LoadingComponent /> : <></>}
            <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <th>Program Category</th>
                  <th>Generate Link</th>
                  <th className=''>Keterangan</th>
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                {programCategoryItems?.length === 0 ? (
                  <tr>
                    <td colSpan={2}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No Properties Found
                      </div>
                    </td>
                  </tr>
                ) : (
                  programCategoryItems?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.name}</td>
                        <td>
                          <span
                            className='badge badge-success'
                            onClick={(e) => {
                              e.preventDefault()
                              copy(
                                `${currentUser?.data?.company?.platform_donation}/program-categories-favorite/${item?.slug}?affiliate_code=${currentUser?.data?.affiliate_code}`
                              )
                              toast.success(`Berhasil menyalin link afiliasi `)
                            }}
                          >
                            Copy Affiliate Link
                          </span>
                        </td>
                        <td className=''>{`Klik tombol di samping untuk manyalin link affiliasi untuk program kategori ${item.slug}`}</td>
                      </tr>
                    )
                  })
                )}
              </tbody>
            </table>
          </div>

          <div className='card-footer'>
            <ReactPaginate
              previousLabel={'«'}
              nextLabel={'»'}
              breakLabel={'...'}
              breakClassName={'page-item'}
              breakLinkClassName={'page-link'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              pageCount={programCategoryModel?.data?.total_pages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={10}
              onPageChange={handlePageClick}
              containerClassName={'pagination pagination-sm m-0 float-right'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              activeClassName={'active'}
            />
          </div>
        </div>
      </KTCard>
    </>
  )
}

const GenerateLinkListWrapper = () => {
  return (
    <>
      <GeneratelinkListComponent />
    </>
  )
}

export default GenerateLinkListWrapper
