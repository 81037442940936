import clsx from 'clsx'
import {useEffect, useRef, useState} from 'react'
import ReactPaginate from 'react-paginate'
import {useNavigate} from 'react-router-dom'
import {KTCard, KTSVG} from '../../../../../../_metronic/helpers'
import {generateCurrencyFormat} from '../../../../../helpers/generate-currency-format'
import {AUTH_LOCAL_STORAGE_KEY, AuthModel, useAuth} from '../../../../auth'
import {LoadingComponent} from '../../../crm/properties-management/components/loading.component'
import {useAffiliatorCompanyFormStore, useAffiliatorCompanyStore} from '../states/affiliator-state'
import {useKorcaStore} from '../../../crm/korca-management/states/korca-management.state'
import AffiliatorCompanyModalComponent from './affiliator-modal.component'
import {dateTimeFormatter} from '../../../../../../_metronic/helpers/datetime-formatter'

const AffiliatorListByDIstrictComponent = () => {
  const {
    getAffiliatorCompanyByKorca,
    affiliatorCompanyByKorcaItems,
    affiliatorCompanyByKorcaLoading,
    affiliatorCompanyByKorcaModel,
  } = useAffiliatorCompanyStore()
  const {getKorcaGroup} = useKorcaStore()

  const {field} = useAffiliatorCompanyFormStore()

  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const [search, setSearch] = useState<string>('')
  const [debounceQuery, setDebounceQuery] = useState<string>('')
  const [exportLoading, setExportLoading] = useState<boolean>(false)

  const firstRender = useRef(true)

  useEffect(() => {
    if (currentUser?.data?.id?.length > 0) {
      getKorcaGroup(currentUser?.data?.id)
      getAffiliatorCompanyByKorca(currentUser?.data?.id, {
        page: 0,
        size: 25,
        search: search,
      })
    }
  }, [currentUser])

  useEffect(() => {
    const timeout = setTimeout(() => {
      console.debug(debounceQuery)
      setDebounceQuery(search)
      if (firstRender.current) {
        firstRender.current = false
        return
      }
      getAffiliatorCompanyByKorca(currentUser?.data?.id, {
        page: 0,
        size: 10,
        filters: `[
          ["email", "ilike", "${search}"], 
          ["or"],
          ["phone_number", "ilike", "${search}"],
          ["or"],
          ["full_name", "ilike", "${search}"],
          ["or"],
          ["district_name", "ilike", "${search}"]
        ]`,
      })
    }, 500)
    return () => {
      clearTimeout(timeout)
    }
  }, [search])

  const handlePageClick = (e: any) => {
    const selectedPage = e.selected
    getAffiliatorCompanyByKorca(currentUser?.data?.id, {page: selectedPage, size: 25})
  }

  // useEffect(() => {
  //   if (submitDone === true && formLoading === false) {
  //     getAffiliatorCompanyByKorca(currentUser?.data?.id, {
  //       page: 0,
  //       size: 10,
  //     })
  //     reset()
  //   }
  // }, [submitDone, formLoading])

  return (
    <>
      <div className='py-5'>
        <div className='rounded border p-5' style={{backgroundColor: 'white'}}>
          <form
            data-kt-search-element='form'
            className='w-100 position-relative'
            autoComplete='off'
          >
            {/*begin::Hidden input(Added to disable form autocomplete)*/}
            <input type='hidden' />
            {/*end::Hidden input*/}
            {/*begin::Icon*/}
            {/*begin::Svg Icon | path: icons/duotune/general/gen021.svg*/}
            <span className='svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y'>
              <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-2' />
            </span>
            {/*end::Svg Icon*/} {/*end::Icon*/}
            {/*begin::Input*/}
            <input
              type='text'
              className='form-control form-control-lg form-control-solid px-15'
              name='search'
              value={search}
              onChange={(e) => setSearch(e?.target?.value)}
              placeholder='Cari berdasarkan nama, email atau nomor hp...'
              data-kt-search-element='input'
            />
            {/*end::Input*/}
          </form>
        </div>
      </div>
      {exportLoading ? (
        <div className='alert alert-info d-flex align-items-center p-5'>
          <div className='d-flex flex-column'>
            <h4 className='mb-1 text-info'>Report on progress</h4>
            <span>Generating your report please wait....</span>
          </div>
        </div>
      ) : null}
      <KTCard>
        <div className='card-header border-0 pt-6'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Affiliator</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Affiliator List</span>
          </h3>
          <div className='card-toolbar'>
            <a
              type='button'
              className='btn btn-primary'
              rel='noreferrer'
              onClick={(e) => {
                e.preventDefault()
                if (!exportLoading) {
                  const tokenLocal = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
                  const token = JSON.parse(tokenLocal) as AuthModel
                  setExportLoading(true)
                  fetch(
                    `${process.env.REACT_APP_API_PROGRAM_URL}/get-affiliators-excel/${currentUser?.data?.id}`,
                    {
                      method: 'GET',
                      headers: {
                        'Content-Type':
                          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        Authorization: `Bearer ${token?.data?.accessToken}`,
                      },
                    }
                  )
                    .then((response) => response.blob())
                    .then((blob) => {
                      const url = window.URL.createObjectURL(new Blob([blob]))

                      const link = document.createElement('a')
                      link.href = url
                      link.download = `Export_Affiliator_${dateTimeFormatter(Date.now())}.xlsx`

                      document.body.appendChild(link)

                      link.click()

                      link.parentNode.removeChild(link)
                      setExportLoading(false)
                    })
                    .catch(() => {
                      setExportLoading(false)
                    })
                }
              }}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
              Export Excel
            </a>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {affiliatorCompanyByKorcaLoading ? <LoadingComponent /> : <></>}
            <table className='table table-row-bordered table-striped table-hover able-row-gray-100 align-middle gs-3 gy-3'>
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <th>Nama</th>
                  <th>No Hp</th>
                  <th>Role</th>
                  <th>Wilayah</th>
                  <th>Total Donasi</th>
                  <th>Total Komisi</th>
                  {/* <th>Action</th> */}
                  <th className='text-end'>Detail</th>
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                {affiliatorCompanyByKorcaLoading === true ? <LoadingComponent /> : null}
                {affiliatorCompanyByKorcaItems?.length === 0 ? (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No Properties Found
                      </div>
                    </td>
                  </tr>
                ) : (
                  affiliatorCompanyByKorcaItems?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                              <a href='#'>
                                <div
                                  className={clsx(
                                    'symbol-label fs-3',
                                    `bg-light-success`,
                                    `text-success`
                                  )}
                                >
                                  {item?.full_name[0]}
                                </div>
                              </a>
                            </div>
                            <div className='d-flex flex-column'>
                              <a href='#' className='text-gray-800 text-hover-primary mb-1'>
                                {item.full_name}
                              </a>
                              <span>{item.email}</span>
                            </div>
                          </div>
                        </td>
                        <td>{item.phone_number}</td>
                        <td>{item.role_name}</td>
                        <td>{item.district_name}</td>
                        <td>{generateCurrencyFormat(item.total_donations ?? 0)}</td>
                        <td>{generateCurrencyFormat(item.total_commissions ?? 0)}</td>

                        {/* <td className='text-end'>
                          {currentUser?.data?.role?.slug === 'superadmin' ||
                          currentUser?.data?.role?.slug === 'admin' ? (
                            <>
                              <a
                                href={`#`}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                onClick={(e) => {
                                  e.preventDefault()
                                  setField(item)
                                  generateForms({
                                    title: 'Update Affiliator',
                                    subtitle: 'Update Affiliator',
                                    action: FormAction.UPDATE,
                                    data: item,
                                    id: item.id,
                                  })
                                }}
                              >
                                <KTSVG
                                  path='/media/icons/duotune/art/art005.svg'
                                  className='svg-icon-3'
                                />
                              </a>
                              <a
                                href='#'
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                onClick={(e) => {
                                  e.preventDefault()
                                  onDelete(item.id, {
                                    page: 0,
                                    size: 10,
                                  })
                                }}
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen027.svg'
                                  className='svg-icon-3'
                                />
                              </a>
                            </>
                          ) : (
                            <></>
                          )}
                        </td> */}
                        <td className='text-end'>
                          <button
                            className='btn btn-success'
                            onClick={(e) => {
                              e.preventDefault()
                              navigate(`/apps/affiliator-management/affiliator-detail/${item?.id}`)
                            }}
                          >
                            Detail
                          </button>
                        </td>
                      </tr>
                    )
                  })
                )}
              </tbody>
            </table>
          </div>

          <div className='card-footer'>
            <ReactPaginate
              previousLabel={'«'}
              nextLabel={'»'}
              breakLabel={'...'}
              breakClassName={'page-item'}
              breakLinkClassName={'page-link'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              pageCount={affiliatorCompanyByKorcaModel?.data?.total_pages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={10}
              onPageChange={handlePageClick}
              containerClassName={'pagination pagination-sm m-0 float-right'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              activeClassName={'active'}
            />
          </div>
        </div>
      </KTCard>
      {field !== undefined && <AffiliatorCompanyModalComponent />}
    </>
  )
}

const AffiliatorListByDistrictWrapper = () => {
  return (
    <>
      <AffiliatorListByDIstrictComponent />
    </>
  )
}

export {AffiliatorListByDistrictWrapper}
