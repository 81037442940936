import {toast} from 'react-toastify'
import {create} from 'zustand'
import {generateFormLogic} from '../../../../../../_metronic/helpers/generate-form-logic'
import {
  FormAction,
  FormInput,
  FormProps,
  SelectData,
} from '../../../../../interfaces/form-action-interfaces'
import {BranchSingleResultInterface} from '../interfaces/branch-single-result.interface'
import {BranchData, BranchResultInterface} from '../interfaces/branch-result.interface'
import {
  BranchWithTotalDonationResultInterface,
  BranchWithTotalDonationData,
} from '../interfaces/branch-with-total-donaiton-result.interface'
import {
  GetAllBranchParam,
  GetAllBranch,
  DeleteBranch,
  SubmitBranch,
  UpdateBranch,
  GetAllBranchWithTotalDonation,
} from '../providers/branch.providers'

interface BranchState {
  branchLoading: boolean
  branchItems: BranchData[]
  branchSelectDatas: SelectData[]
  branchModel?: BranchResultInterface
  getBranchs: (params: GetAllBranchParam) => void

  branchWithTotalDonationLoading: boolean
  branchWithTotalDonationItems: BranchWithTotalDonationData[]
  branchWithTotalDonationModel?: BranchWithTotalDonationResultInterface
  getBranchWithTotalDonation: (params: GetAllBranchParam) => void

  onDelete: (id: string, params: GetAllBranchParam) => void
}

export const useBranchStore = create<BranchState>((set, get) => ({
  branchLoading: false,
  branchItems: [],
  branchSelectDatas: [],
  branchModel: undefined,
  getBranchs: async (params: GetAllBranchParam) => {
    set({branchLoading: true})
    const response = await GetAllBranch(params)
    set({branchModel: response})
    if (response.status) {
      if (response.data.items?.length > 0) {
        let transformDatas: SelectData[] = []
        for (const item of response.data.items) {
          transformDatas.push({
            label: item.name,
            value: item.id,
          })
        }
        set({
          branchItems: response.data.items,
          branchSelectDatas: transformDatas,
        })
      } else {
        set({
          branchItems: [],
          branchSelectDatas: [],
        })
      }
    } else {
      set({
        branchItems: [],
        branchSelectDatas: [],
      })
    }
    set({branchLoading: false})
  },
  branchWithTotalDonationLoading: false,
  branchWithTotalDonationItems: [],
  branchWithTotalDonationModel: undefined,
  getBranchWithTotalDonation: async (params: GetAllBranchParam) => {
    set({branchWithTotalDonationLoading: true})
    const response = await GetAllBranchWithTotalDonation(params)
    set({branchWithTotalDonationModel: response})
    if (response.status) {
      if (response.data.items?.length > 0) {
        set({
          branchWithTotalDonationItems: response.data.items,
        })
      } else {
        set({
          branchWithTotalDonationItems: [],
        })
      }
    } else {
      set({
        branchWithTotalDonationItems: [],
      })
    }
    set({branchWithTotalDonationLoading: false})
  },
  onDelete: async (id: string, params: GetAllBranchParam) => {
    const confirm = window.confirm('Apakah anda yakin ingin menghapus data ini?')
    if (confirm === true) {
      set({branchLoading: true})
      const response = await DeleteBranch(id)
      if (response.status) {
        toast.success(response.message)
      } else {
        toast.error(response.message)
      }
      get().getBranchs(params)
      set({branchLoading: false})
    }
  },
}))

const formInitialState = {
  loading: false,
  forms: [],
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
}

export interface BranchFormState {
  field?: BranchData
  setField?: (item: BranchData) => void
  forms?: FormInput[]
  setForms?: (index: number, value: any) => void
  generateForms: (formParam: FormProps) => void
  setFormSelectData: (index: number, selectData: SelectData[]) => void
  formParam: FormProps
  reset: () => void
  formLoading?: boolean
  submitDone?: boolean
  onSubmit: () => void
}

export const useBranchFormStore = create<BranchFormState>((set, get) => ({
  field: undefined,
  setField: (item: BranchData) => {
    set({field: item})
  },
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({forms: forms})
  },
  generateForms: (formParam: FormProps) => {
    set({formParam: formParam})
    let forms: FormInput[] = []
    forms = [
      {
        id: 'name',
        title: 'Branch Name',
        placeholder: 'Branch Name...',
        type: 'text',
        name: 'name',
        value: get().field?.name ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'acronym',
        title: 'Acronym',
        placeholder: 'Acronym...',
        type: 'text',
        name: 'acronym',
        value: get().field?.acronym ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
    ]

    set({forms: forms})
  },
  reset: () => {
    set(formInitialState)
    return
  },
  onSubmit: async () => {
    let form = {}
    for (const item of get().forms) {
      form = {
        ...form,
        [item.name]: generateFormLogic(item.type, item.value),
      }
    }

    let res: BranchSingleResultInterface = {}
    if (get().formParam.action === FormAction.CREATE) res = await SubmitBranch(form)
    if (get().formParam.action === FormAction.UPDATE)
      res = await UpdateBranch(get().formParam?.id, form)

    console.log(res)
    if (res.status) {
      toast.success(res.message)
      set({submitDone: true, formLoading: false, field: undefined})
      return
    } else {
      toast.error(res.message)
      set({submitDone: false, formLoading: false})
      return
    }
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    }
    set({forms: forms})
  },
}))
