import {create} from 'zustand'
import {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Connection,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  OnConnect,
  OnEdgesChange,
  OnNodesChange,
} from 'reactflow'
import {v4 as uuidv4} from 'uuid'
import {
  FormAction,
  FormInput,
  FormProps,
  SelectData,
} from '../../../../../interfaces/form-action-interfaces'
import {generateFormLogic} from '../../../../../../_metronic/helpers/generate-form-logic'
import {
  DeleteFlow,
  GetAllFlow,
  GetAllFlowParam,
  GetTransactionFlow,
  GetTransactionFlowParam,
  SubmitFlow,
  UpdateFlow,
} from '../providers/flow.provider'
import {toast} from 'react-toastify'
import {FlowResultInterface} from '../interfaces/flow-result.interface'
import {FlowSingleResultInterfaceData} from '../interfaces/flow-single-result.interface'
import {CardNodeData} from '../components/card-node-v2.component'

interface FlowBuilderState {
  flowNodes: Node<CardNodeData>[]
  flowEdges: Edge<any>[]
  setFlowNodes: (node: Node<CardNodeData>) => void
  setFlowNodesV2: (node: Node<CardNodeData>[]) => void
  setFlowEdges: (edge: Edge<any>) => void
  onChangeFlowNodes: (nodes: Node<CardNodeData>[]) => void
  onChangeFlowEdges: (edges: Edge<any>[]) => void
  generateFlowNodes: (flow?: FlowSingleResultInterfaceData) => void
  generateFlowEdges: (flow?: FlowSingleResultInterfaceData) => void
  onModifyFlowNode: (node: Node<CardNodeData>) => void
  onModifyFlowEdge: (edge: Edge<any>) => void
  getFlows: (params: GetAllFlowParam) => void
  getTransactionFlows: (params: GetTransactionFlowParam) => void
  flowLoading: boolean
  flowModel?: FlowResultInterface
  flowItems: FlowSingleResultInterfaceData[]
  onDelete: (id: string, params: GetAllFlowParam) => void
  selectedNode?: Node<CardNodeData>
  setSelectedNode?: (node: Node<CardNodeData>) => void
}

export const useFlowBuilderStore = create<FlowBuilderState>((set, get) => ({
  flowNodes: [],
  flowEdges: [],
  flowItems: [],
  flowModel: undefined,
  flowLoading: false,
  selectedNode: undefined,
  setSelectedNode: (node: Node<CardNodeData>) => {
    set({selectedNode: node})
  },
  getFlows: async (params: GetAllFlowParam) => {
    set({flowLoading: true})
    const response = await GetAllFlow(params)
    set({flowModel: response})
    if (response) {
      if (response.status) {
        if (response.data?.items?.length > 0) {
          set({flowItems: response.data.items})
        } else {
          set({flowItems: []})
        }
      } else {
        set({flowItems: []})
      }
    } else {
      set({flowItems: []})
    }
    set({flowLoading: false})
  },
  getTransactionFlows: async (params: GetTransactionFlowParam) => {
    set({flowLoading: true})
    const response = await GetTransactionFlow(params)
    set({flowModel: response})
    if (response) {
      if (response.status) {
        if (response.data?.items?.length > 0) {
          set({flowItems: response.data.items})
        } else {
          set({flowItems: []})
        }
      } else {
        set({flowItems: []})
      }
    } else {
      set({flowItems: []})
    }
    set({flowLoading: false})
  },
  setFlowNodes: (node: Node<CardNodeData>) => {
    const nodes = [...get().flowNodes]
    nodes.push(node)
    set({flowNodes: nodes})
  },
  setFlowNodesV2: (node: Node<CardNodeData>[]) => {
    set({flowNodes: node})
  },
  setFlowEdges: (edge: Edge<any>) => {
    const edges = [...get().flowEdges]
    edges.push(edge)
    set({flowEdges: edges})
  },
  onChangeFlowNodes: (nodes: Node<CardNodeData>[]) => {
    set({flowNodes: nodes})
  },
  onChangeFlowEdges: (edges: Edge<any>[]) => {
    set({flowEdges: edges})
  },
  onModifyFlowNode: (node: Node<CardNodeData>) => {
    const nodes = [...get().flowNodes]
    const index = nodes.findIndex((el) => el.id === node.id)
    if (index > -1) {
      nodes[index] = node
      set({flowNodes: nodes})
    }
  },
  onModifyFlowEdge: (edge: Edge<any>) => {
    const edges = [...get().flowEdges]
    const index = edges.findIndex((el) => el.id === edge.id)
    if (index > -1) {
      edges[index] = edge
      set({flowEdges: edges})
    }
  },
  generateFlowNodes: (flow?: FlowSingleResultInterfaceData) => {
    if (flow === undefined) {
      const node: Node<CardNodeData> = {
        id: uuidv4(),
        position: {x: 0, y: 0},
        data: {
          title: 'Flow #1',
          message: '',
          buttons: [],
          lists: [],
          type: 'text',
          media: '',
          save_as: '',
        },
        type: 'cardNodeComponent',
      }
      const nodes: Node<CardNodeData>[] = []
      nodes.push(node)
      set({flowNodes: nodes})
    } else {
      const nodes: Node<CardNodeData>[] = []
      for (const item of flow?.flow_builder?.flow_builder_nodes) {
        nodes.push({
          id: item.id,
          position: {x: item.position.x, y: item.position.y},
          data: {
            title: item.data.title,
            message: item.data.message,
            buttons: item.data.buttons,
            type: item.data.type,
            media: item.data.media,
            lists: item.data.lists,
            save_as: item.data.save_as,
          },
          type: item.type,
          width: item.width,
          height: item.height,
          selected: item.selected,
          positionAbsolute: {
            x: item.positionAbsolute.x,
            y: item.positionAbsolute.y,
          },
          dragging: item.dragging,
        })
      }
      set({flowNodes: nodes})
    }
  },
  generateFlowEdges: (flow?: FlowSingleResultInterfaceData) => {
    if (flow !== undefined) {
      const edges: Edge<any>[] = []
      for (const item of flow?.flow_builder?.flow_builder_edges) {
        edges.push({
          source: item.source,
          sourceHandle: item.sourceHandle,
          target: item.target,
          targetHandle: item.targetHandle,
          id: item.id,
        })
      }
      set({flowEdges: edges})
    }
  },
  onDelete: async (id: string, params: GetAllFlowParam) => {
    const confirm = window.confirm('Apakah anda yakin ingin menghapus data ini?')
    if (confirm === true) {
      set({flowLoading: true})
      const response = await DeleteFlow(id)
      if (response.status) {
        toast.success(response.message)
      } else {
        toast.error(response.message)
      }
      set({flowLoading: false})
      get().getFlows(params)
    }
  },
}))

export interface FlowBuilderFormState {
  field?: FlowSingleResultInterfaceData
  setField?: (field: FlowSingleResultInterfaceData) => void
  forms?: FormInput[]
  setForms?: (index: number, value: any) => void
  setFormSelectData: (index: number, selectData: SelectData[]) => void
  generateForms: (formParam: FormProps) => void
  formParam: FormProps
  reset: () => void
  selectDatas: SelectData[] // if type is select
  setSelectDatas: (index: number, value: string) => void // if type is select
  addSelectDatas: () => void
  deleteSelectDatas: (index: number) => void
  generateSelectDatas: (items: SelectData[]) => void
  onSubmit: (nodes: Node<CardNodeData>[], edges: Edge<any>[], companyId: string) => void
  formLoading?: boolean
  submitDone?: boolean
}

const formInitialState = {
  forms: [],
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
  field: undefined,
}

export const useFlowBuilderFormState = create<FlowBuilderFormState>((set, get) => ({
  formParam: undefined,
  field: undefined,
  forms: [],
  setField: (field?: FlowSingleResultInterfaceData) => {
    set({field: field})
  },
  selectDatas: [],
  generateSelectDatas: (items: SelectData[]) => {
    set({selectDatas: items})
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    }
    set({forms: forms})
  },
  deleteSelectDatas: (index: number) => {
    const sd = [...get().selectDatas]
    sd.splice(index, 1)
    set({selectDatas: sd})
  },
  setSelectDatas: (index: number, value: string) => {
    const sd = [...get().selectDatas]
    sd[index] = {
      value: value,
      label: value,
    }
    set({selectDatas: sd})
  },
  addSelectDatas: () => {
    const sd = [...get().selectDatas]
    sd.push({
      value: '',
      label: '',
    })
    set({selectDatas: sd})
  },
  setForms: (index: number, value: any) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({forms: forms})
  },
  generateForms: async (formParam: FormProps) => {
    set({formParam: formParam})
    let forms: FormInput[] = []
    forms = [
      {
        id: 'flow_name',
        title: 'Flow Name',
        placeholder: 'Enter Flow Name...',
        type: 'text',
        name: 'flow_name',
        value: get().field?.flow_name ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'trigger',
        title: 'Trigger',
        placeholder: 'Enter Trigger...',
        type: 'text',
        name: 'trigger',
        value: get().field?.trigger ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'company_channel_property_id',
        title: 'Number',
        placeholder: 'Enter Number...',
        type: 'select-with-text',
        name: 'company_channel_property_id',
        value: {
          label: get().field?.company_channel_property?.account_id,
          value: get().field?.company_channel_property?.id,
        },
        selectData: [],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'flow_type',
        title: 'Flow Type',
        type: 'select-with-text',
        name: 'flow_type',
        // value: get().field?.flow_type ?? '',
        value: {
          label: get().field?.flow_type ?? '',
          value: get().field?.flow_type ?? '',
        },
        selectData: [
          {label: 'Marketing', value: 'Marketing'},
          {label: 'Transactional', value: 'Transactional'},
        ],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
    ]
    set({forms: forms})
  },
  reset: () => {
    set(formInitialState)
    return
  },
  onSubmit: async (nodes: Node<CardNodeData>[], edges: Edge<any>[], companyId: string) => {
    set({formLoading: true, submitDone: false})

    let formData = {}
    for (const v of get().forms) {
      formData = {
        ...formData,
        [v.name]: generateFormLogic(v.type, v?.value ?? ''),
      }
    }

    formData = {
      ...formData,
      flow_builder: {
        flow_builder_nodes: nodes,
        flow_builder_edges: edges,
      },
      company_id: companyId,
    }

    let res: any = {}
    if (get().formParam.action === FormAction.CREATE) res = await SubmitFlow(formData)
    if (get().formParam.action === FormAction.UPDATE)
      res = await UpdateFlow(get().formParam?.id, formData)

    console.log(res)

    if (res.status) {
      toast.success(res.message)
      set({submitDone: true, formLoading: false})
      return
    } else {
      toast.error(res.message)
      set({submitDone: false, formLoading: false})
      return
    }
  },
}))

type RFState = {
  nodes: Node<CardNodeData>[]
  edges: Edge[]
  onNodesChange: OnNodesChange
  onEdgesChange: OnEdgesChange
  onConnect: OnConnect
  addNodes: () => void
  generateNodes: (field: FlowSingleResultInterfaceData) => void
  generateEdges: (field: FlowSingleResultInterfaceData) => void
  selectedNode?: Node<CardNodeData>
  setSelectedNode?: (node: Node<CardNodeData>) => void
  modifyNode?: (node: Node<CardNodeData>) => void
  resetReactFlow: () => void
}

// this is our useStore hook that we can use in our components to get parts of the store and call actions
export const useReactFlowStore = create<RFState>((set, get) => ({
  nodes: [],
  edges: [],
  resetReactFlow: () => {
    set({
      nodes: [],
      edges: [],
    })
  },
  onNodesChange: (changes: NodeChange[]) => {
    set({
      nodes: applyNodeChanges(changes, get().nodes),
    })
  },
  onEdgesChange: (changes: EdgeChange[]) => {
    set({
      edges: applyEdgeChanges(changes, get().edges),
    })
  },
  onConnect: (connection: Connection) => {
    set({
      edges: addEdge(connection, get().edges),
    })
  },
  addNodes: () => {
    set((state) => ({
      nodes: [
        ...state.nodes,
        {
          id: uuidv4(),
          position: {x: 0, y: 200},
          data: {
            title: `Flow #${state.nodes?.length + 1}`,
            message: '',
            buttons: [],
            type: 'text',
            media: '',
            lists: [],
            text_based_actions: [],
            save_as: '',
            product_id: '',
            product_name: '',
          },
          type: 'cardNodeComponent',
        },
      ],
    }))
  },
  generateNodes: (field: FlowSingleResultInterfaceData) => {
    if (field === undefined) {
      const node: Node<CardNodeData> = {
        id: uuidv4(),
        position: {x: 0, y: 0},
        data: {
          title: 'Flow #1',
          message: '',
          buttons: [],
          lists: [],
          text_based_actions: [],
          text_based_action_numbers: [],
          type: 'text',
          media: '',
          save_as: '',
          product_id: '',
          product_name: '',
        },
        type: 'cardNodeComponent',
      }
      set((state) => ({
        nodes: [...state.nodes, node],
      }))
    } else {
      for (const item of field?.flow_builder?.flow_builder_nodes) {
        set((state) => ({
          nodes: [
            ...state.nodes,
            {
              id: item.id,
              position: {x: item.position.x, y: item.position.y},
              data: {
                title: item.data.title,
                message: item.data.message,
                buttons: item.data.buttons,
                type: item.data.type,
                media: item.data.media,
                lists: item.data.lists,
                text_based_actions: item.data.text_based_actions,
                text_based_action_numbers: item.data.text_based_action_numbers,
                save_as: item.data.save_as,
                product_id: item.data?.product_id,
                product_name: item.data?.product_name,
              },
              type: item.type,
              width: item.width,
              height: item.height,
              selected: item.selected,
              positionAbsolute: {
                x: item.positionAbsolute.x,
                y: item.positionAbsolute.y,
              },
              dragging: item.dragging,
            },
          ],
        }))
      }
    }
  },
  generateEdges: (field: FlowSingleResultInterfaceData) => {
    if (field !== undefined) {
      for (const item of field?.flow_builder?.flow_builder_edges) {
        set((state) => ({
          edges: [
            ...state.edges,
            {
              source: item.source,
              sourceHandle: item.sourceHandle,
              target: item.target,
              targetHandle: item.targetHandle,
              id: item.id,
            },
          ],
        }))
      }
    }
  },
  setSelectedNode: (node: Node<CardNodeData>) => {
    set({selectedNode: node})
  },
  modifyNode: (nodeData: Node<CardNodeData>) => {
    set((state) => ({
      nodes: state.nodes.map((node) =>
        node.id === nodeData?.id
          ? {
              ...node,
              data: {
                ...nodeData?.data,
              },
            }
          : node
      ),
    }))
  },
}))
